<template>
  <div class="container px-0 h-100">
    LIFE
  </div>
</template>

<script>
export default {
  name: "CalculatorLifePageView"
};
</script>
